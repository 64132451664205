import React from 'react'
import { collection, query, where, orderBy, getDocs } from "firebase/firestore";

export default class FirstAiderDashboard extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      user: props.user,
	  events: [],
	  db: props.db,
    };
  }

  async componentDidMount() {
	  // const querySnapshot = await getDocs(query(collection(this.state.db, "panic-events"), where("resolved", "==", false), orderBy("timestamp", "desc")));
	  const q = query(collection(this.state.db, "panic-events"), where("resolved", "==", false), orderBy("timestamp", "desc"));
	  setInterval(async () => {
		let eventsTemp = [];
		const querySnapshot = await getDocs(q);
		querySnapshot.forEach((doc) => {
			eventsTemp.push({
				...doc.data(),
				id: doc.id,
			});
		});
		this.setState({
			events: eventsTemp,
		});
	}, 1000)
  }

  render() {
	return (
		<div>
			{this.state.events.length > 0 ?
			<table>
				<tbody>
				<tr>
					<td>Time</td>
					<td>Notes</td>
					<td>See more</td>
				</tr>
				{
					this.state.events.map((event) => 
					<tr key={event.id}>
						<td>{(new Date(event.timestamp.seconds*1000)).toTimeString().substring(0, 9)}</td>
						<td>{event.patient}</td>
						<td><a href={`/event/${event.id}`}>Details</a></td>
					</tr>)
				}
				</tbody>
			</table>
			:
			<p>No events!</p>
			}
		</div>
	);
  }
}
